/**
 * Used this package as reference https://unpkg.com/browse/scandit-sdk@4.6.1/src/lib/cameraAccess.ts
 *
 * Handle localized camera labels. Supported languages:
 * English, German, French, Spanish (spain), Portuguese (brasil), Portuguese (portugal), Italian,
 * Chinese (simplified), Chinese (traditional), Japanese, Russian, Turkish, Dutch, Arabic, Thai, Swedish,
 * Danish, Vietnamese, Norwegian, Polish, Finnish, Indonesian, Hebrew, Greek, Romanian, Hungarian, Czech,
 * Catalan, Slovak, Ukraininan, Croatian, Malay, Hindi.
 */
export const backCameraKeywords = [
  'rear',
  'back',
  'world',
  'rück',
  'arrière',
  'trasera',
  'trás',
  'traseira',
  'posteriore',
  '后面',
  '後面',
  '背面',
  '后置', // alternative
  '後置', // alternative
  '背置', // alternative
  'задней',
  'الخلفية',
  '후',
  'arka',
  'achterzijde',
  'หลัง',
  'baksidan',
  'bagside',
  'sau',
  'bak',
  'tylny',
  'takakamera',
  'אחורית',
  'πίσω',
  'spate',
  'hátsó',
  'zadní',
  'darrere',
  'zadná',
  'задня',
  'stražnja',
  'belakang',
  'बैक',
]
